.map {
    width: 98%;
    height: 30%;
    overflow: hidden;
    position:fixed;
}
.mapboxgl-ctrl-bottom-right {
    display:none !important;
}

.mapboxgl-canvas {
    width: 100% !important;
    height: 400px !important;
}
  
#marker {
    background-image: url('https://upload.wikimedia.org/wikipedia/ro/thumb/5/51/Logo_Universitatea_Politehnica_din_Bucure%C8%99ti.svg/1024px-Logo_Universitatea_Politehnica_din_Bucure%C8%99ti.svg.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.mapboxgl-popup {
    max-width: 400px;
}
  
.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}