@import '../../../main.scss';
@import '../../../theme.scss';

  a, a:active, a:hover {
    color: $black;
  }

.deleteContainer {
  margin-top: 2%;
  width: auto;
  font-size: 14px;
  &:hover {
    background-color: #e8e7e7;
  }
}

.input_container {
  margin-right: 5%;
  display: flex;
  align-items: center;
}

input {
  margin-right: .4rem;
}

.delete {
  outline: none;
  background-color: transparent;
  border: none;
  float: right;
  &:hover {
    cursor: pointer;
  }
}

.container {
  @include layout(row, center, center);
  font-family: $font;
  margin: 5% 12% !important;
  
  label {
    @include layout(row, flex-start, flex-start);
    color: $black;
    font-family: $font;
    padding: 20px 0 0 0;
  }
  
  .button {
    @include layout(row, flex-start, flex-start);
    h6 {
      text-align: center;
      color: $white;
      font-size: 0.9rem;
      font-weight: 100;
      margin:2% 4% 2% 2%;
    }
    h6 span { 
        background-color: $secondColor; 
        border-radius: 5px;
        padding: 7px 13px; 
    }
  }
  h5 {
    margin: 2% 0;
    font-size: 0.9rem;
  }
  

  .firstLine {
    @include layout(column, flex-start, flex-start);
    width: 100%;
    :global {
      .ant-row {
        width:100% !important;
      }
    }
    .checkbox {
      @include layout(row, flex-start, flex-start);
    }
    textarea, .numberInput {
      border: 0.5px solid #d9d9d9;
      outline: none;
      resize: none;
      box-shadow: none;
      border-radius: 4px !important;
      color: rgb(0, 0, 0) !important;
      margin-bottom: 1%;
      width: 100%;
      height: 2.2rem;
      padding: 5px 5px 5px 1%;
      &:hover, &:focus, &:active {
        border: 0.5px solid #d9d9d9;
        outline: none;
        resize: none;
        box-shadow: none;
        border-radius: 4px !important;
      }
    }

    .customSelect {
      text-align: left;
    }

    textarea::placeholder, .numberInput::placeholder {
      opacity: 0.25;
    }

  }
  .secondLine{
    margin-top: 5%;
    width: 100%;
    @include layout(row, space-between, flex-start);

    .firstColumn {
      @include layout(row, space-between, center);
      width: 250px;
    }
    .secondColumn {
      @include layout(row, flex-end, center);
    }
  }

  :global {
    .ant-picker, .ant-picker-range, .ant-picker-focused{
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
      border-width: 1px !important;
      border-radius: 5px !important;
      padding:10px !important;
      transition: none !important;
    }
     .ant-picker:hover,.ant-picker-range:hover, .ant-picker-focused:hover{
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
      border-width: 1px !important;
      border-radius: 5px !important;
      transition: none !important;
    }
    .ant-picker-range .ant-picker-active-bar {
      bottom: -1px;
      height: 2px;
      margin-left: 11px;
      background: #d9d9d9 !important;
    }
    .ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-start .ant-picker-cell-range-end .ant-picker-cell-today .ant-picker-cell-selected {
      color: #fff;
      background: #1c3334;
      border: #1c3334;
    }

    .ant-select-selection-item {
      display: flex !important;
    }
}
}

@media only screen and (max-width: 810px) {

  a, a:active, a:hover {
    color: $black;
  }
  .container {
    @include layout(row, center, center);
    font-family: $font;
    margin: 5% 12%;
    width: 70%;
  
    
    label {
      @include layout(row, flex-start, flex-start);
      color: $black;
      font-family: $font;
      padding: 20px 0 0 0;
    }
    
    
    .button {
      @include layout(row, flex-start, flex-start);
      h6 {
        text-align: center;
        color: $white;
        font-size: 0.9rem;
        font-weight: 100;
        margin:2% 4% 2% 2%;
      }
      h6 span { 
          background-color: $secondColor; 
          border-radius: 5px;
          padding: 7px 13px; 
      }
    }
    h5 {
      margin: 2% 0;
      font-size: 0.9rem;
    }
    
  
    .firstLine {
      @include layout(column, flex-start, flex-start);
      width: 100%;
      :global {
        .ant-row {
          width:100% !important;
        }
      }
      .checkbox {
        @include layout(row, flex-start, flex-start);
      }
      .container2 {
        width: 100%;
        margin:0;
      }
      textarea{
        border: 0.5px solid #d9d9d9;
        outline: none;
        resize: none;
        box-shadow: none;
        border-radius: 4px !important;
        padding: 5px 5px;
        color:rgb(0, 0, 0) !important;
        margin-bottom: 1%;
        width:100%;
        height: 2.2rem;
        &:hover, &:focus, &:active{
          border: 0.5px solid #d9d9d9;
          outline: none;
          resize: none;
          box-shadow: none;
          border-radius: 4px !important;
        }
      }
    }
    .secondLine{
      margin-top: 5%;
      width: 100%;
      @include layout(row, space-between, flex-start);
  
      .firstColumn {
        @include layout(row, flex-start, center);
      }
      .secondColumn {
        @include layout(row, flex-end, center);
      }
    }
  
    :global {
      .ant-picker, .ant-picker-range, .ant-picker-focused{
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        border-width: 1px !important;
        border-radius: 5px !important;
        padding:10px !important;
        transition: none !important;
      }
       .ant-picker:hover,.ant-picker-range:hover, .ant-picker-focused:hover{
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        border-width: 1px !important;
        border-radius: 5px !important;
        transition: none !important;
      }
      .ant-picker-range .ant-picker-active-bar {
        bottom: -1px;
        height: 2px;
        margin-left: 11px;
        background: #d9d9d9 !important;
      }
      .ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-start .ant-picker-cell-range-end .ant-picker-cell-today .ant-picker-cell-selected {
        color: #fff;
        background: #1c3334;
        border: #1c3334;
      }
      .ant-select-focused:not(.ant-select-disabled) .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #d9d9d9 !important;
      }

      .ant-select-selection-item {
        display: flex !important;
      }
  }
  }

}


@media only screen and (max-width: 450px) {

  a, a:active, a:hover {
    color: $black;
  }
  .container {
    @include layout(row, center, center);
    font-family: $font;
    margin: 5% 12%;
    width:80%;
  
    
    label {
      @include layout(row, flex-start, flex-start);
      color: $black;
      font-family: $font;
      padding: 20px 0 0 0;
    }
    
    
    .button {
      @include layout(row, flex-start, flex-start);
      h6 {
        text-align: center;
        color: $white;
        font-size: 0.9rem;
        font-weight: 100;
        margin:2% 4% 2% 2%;
      }
      h6 span { 
          background-color: $secondColor; 
          border-radius: 5px;
          padding: 7px 13px; 
      }
    }
    h5 {
      margin: 2% 0;
      font-size: 0.9rem;
    }
    
  
    .firstLine {
      @include layout(column, flex-start, flex-start);
      width: 100%;
      :global {
        .ant-row {
          width:100% !important;
        }
      }
      .checkbox {
        @include layout(row, flex-start, flex-start);
      }
      .container2 {
        margin:0;
      }
      textarea{
        border: 0.5px solid #d9d9d9;
        outline: none;
        resize: none;
        box-shadow: none;
        border-radius: 4px !important;
        padding: 5px 5px;
        color:rgb(0, 0, 0) !important;
        margin-bottom: 1%;
        width:100%;
        height: 2.2rem;
        &:hover, &:focus, &:active{
          border: 0.5px solid #d9d9d9;
          outline: none;
          resize: none;
          box-shadow: none;
          border-radius: 4px !important;
        }
      }
    }
    .secondLine{
      margin-top: 5%;
      width: 100%;
      @include layout(column, center, center);
  
      .firstColumn {
        @include layout(row, flex-start, center);
        margin-bottom: 5%;
      }
      .secondColumn {
        @include layout(row, flex-end, center);
      }
    }
  
    :global {
      .ant-picker, .ant-picker-range, .ant-picker-focused{
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        border-width: 1px !important;
        border-radius: 5px !important;
        padding:10px !important;
        transition: none !important;
      }
       .ant-picker:hover,.ant-picker-range:hover, .ant-picker-focused:hover{
        border-color: #d9d9d9 !important;
        box-shadow: none !important;
        border-width: 1px !important;
        border-radius: 5px !important;
        transition: none !important;
      }
      .ant-picker-range .ant-picker-active-bar {
        bottom: -1px;
        height: 2px;
        margin-left: 11px;
        background: #d9d9d9 !important;
      }
      .ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-start .ant-picker-cell-range-end .ant-picker-cell-today .ant-picker-cell-selected {
        color: #fff;
        background: #1c3334;
        border: #1c3334;
      }

      .ant-select-selection-item {
        display: flex !important;
      }
  }
  }
}