@mixin navBar {
    @include layout(row, center, flex-start);
    width: 20%;

      .openLink {
        text-decoration: none;
        font-size: 20px;
        color: #e6e0e0;
        @include layout(row, center, center);
        margin-top: 15px;
      }

      .openLink > button {
        margin-right: auto;
        margin-left: auto;
      }

  }