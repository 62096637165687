@import '../../main.scss';
@import '../../theme.scss';
.wrapper {
  @include layout(row, center, flex-start);
  width:100;
}
.container {
  @include layout(column, flex-start, flex-start);
  width:100%;
  font-family: $font;
  padding:1% 13%;
  h2{
    color:$primaryColor;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 5% 0%;
  }
  .info{
    @include layout(column, flex-start, flex-start);
    width:100%;
    padding: 1% 5%;
    text-align: left;
    font-weight: bold;
    h3{
      font-size: 0.9rem;
    }
    h4 {
      padding: 1% 5%;
      font-size: 1.1rem;
      font-family: $font;
      color:$primaryColor;
      font-weight: bold;
    }
    p{
      font-family: $font;
    }
    img {
      width: 100%;
      height:30%;
      margin-top: 5%;
    }
  }
  .info2{
    @include layout(column, flex-start, flex-start);
    width:65%;
    padding: 1% 5%;
    text-align: left;
    font-weight: bold;
    img {
      width: 100%;
      height:30%;
      margin-top: 5%;
    }
  }
  .map {
    width:100%;
    padding: 1% 5%;
    @include layout(column, flex-start, flex-start);
  }
  
}