@import './theme.scss';

@mixin layout($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin content {
    width: 100%;
    min-height: calc(100vh - 10vh);
    background-color: $white;
    padding: 4vh 7vw 4vh;
}

@mixin header {
  width: 95% ;
  height: 10vh;
  background-color: $white;
  @include layout(row, space-between, center);
}

@mixin footer {
    width: 100%;
    height: auto;
    background-color: $black;
    @include layout(column, flex-start, flex-start);
    @media only screen and (max-height: 750px) {
      width: 100%;
      height: auto;
      background-color: $black;
      @include layout(column, flex-start, flex-start);
    }
}

@mixin text($color, $font) {
  font-family: $font;
  color: $color;
  letter-spacing: 0.5px;
}

@mixin headerText {
  @include text($black, $font);
}

@mixin footerText {
  @include text($white, $font);
  margin-bottom: 0;
}
