@import '../../main.scss';
@import '../../theme.scss';

.wrapper {
  font-family:$font;
  @include layout(row, space-between, center);
  margin:5% 7% 0% 7%;
  .container {
    @include layout(column, center, center);
    img {
      width:25rem;
    }
    .fadeinimage {
      animation: fadeIn 5s;
      -webkit-animation: fadeIn 5s;
      -moz-animation: fadeIn 5s;
      -o-animation: fadeIn 5s;
      -ms-animation: fadeIn 5s;
    }
    @keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
      0% {opacity:0;}
      100% {opacity:1;}
    }
    .info{
      @include layout(column, center, center);
      width:70%;
      .button {
        @include layout(row, center, center);
      }
    }
    
  }
  :global {
    .ml15 {
      font-weight: 800;
      font-size: 3.8em;
      text-transform: uppercase;
      letter-spacing: 0.5em;
    }
    
    .ml15 .word {
      display: inline-block;
      line-height: 1em;
    }
  }
  .svg {
    width:30vw;
  }
}


@media only screen and (max-width: 1200px) {
  .wrapper {
    font-family:$font;
    width:86%;
    min-height: 75vh;
    @include layout(row, space-between, center);
    margin:5% 7% 0% 7%;
    .container {
      @include layout(column, center, center);
      h1 {
        font-size: 1rem;
      }
      img {
        width:15rem;
      }
      .fadeinimage {
        animation: fadeIn 5s;
        -webkit-animation: fadeIn 5s;
        -moz-animation: fadeIn 5s;
        -o-animation: fadeIn 5s;
        -ms-animation: fadeIn 5s;
      }
      @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-webkit-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-o-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-ms-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      .info{
        @include layout(column, center, center);
        width:70%;
        .button {
          @include layout(row, center, center);
          h2 {
            font-size: 1rem;
          }
        }
      }
    } 
    .svg {
        width:23rem;
    }
  }
}


@media only screen and (max-width: 700px) {
  .wrapper {
    font-family:$font;
    width:86%;
    min-height: 75vh;
    @include layout(row, space-between, center);
    margin:5% 7% 0% 7%;
    .container {
      @include layout(column, center, center);
      h1 {
        font-size: 1rem;
      }
      img {
        width:10rem;
      }
      .fadeinimage {
        animation: fadeIn 5s;
        -webkit-animation: fadeIn 5s;
        -moz-animation: fadeIn 5s;
        -o-animation: fadeIn 5s;
        -ms-animation: fadeIn 5s;
      }
      @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-moz-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-webkit-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-o-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      
      @-ms-keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
      }
      .info{
        @include layout(column, center, center);
        width:70%;
        .button {
          @include layout(row, center, center);
          h2 {
            font-size: 1rem;
          }
        }
      }
    } 
    .svg {
      display:none;
    }
  }
}
